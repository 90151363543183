@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400,400italic,700,700italic);

@import 'old/style';
@import 'old/layout';

@import 'old/as-fixed';
@import 'old/share';

@import 'global/changes';
@import 'old/mobile'; // <link rel="StyleSheet" type="text/css" href="/theme/mobile.css" media="screen and (max-width:600px)">

//@import 'front-page/referentie-slider';
//@import 'front-page/slider';
//@import 'front-page/usps';
//@import 'vacature/single-vacature';
//@import 'vacature/single-vacature-card';
//@import 'vacature/single-vacature-popup';

