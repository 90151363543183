body.cms {
  padding-top:39px;
}
body.cms .AsMainTaskbar {
  position:fixed;
  top:0;
  left:0;
  z-index:2000;
  height:39px;
  width:100%;
  box-shadow:         0px 2px 3px 0px rgba(0,0,0,0.6);
}
body.cms .AsMainTaskbar .AsTaskbarContent {
  width:98%;
  /*max-width:1060px;*/
}
body.mobile.cms .AsMainTaskbar {
  display:none;
}


