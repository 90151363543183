@media (max-width: 600px){
  #footer ul.contact li a{
    width: 100%;
  }
      body {
  font-size:15px;
  line-height:19px;
}

#wrapper {
  padding-top:114px;
}

#header {
  border:0;
}

#zoeken,
#header .contact,
#stuurdoor,
.referenties,
.containter div.naarboven { display:none; }

#menu {
  margin-top:-87px;
  z-index:130000;
}

#logo {
  float:right;
  margin:0;
  margin-right:10px;
}
#logo img {
  width:166px;
}

.containter .wrapper {
  max-width:576px;
  padding:33px 30px;
}


.page_referenties .wrapper {
  max-width:280px;
}
.page_referenties .wrapper h1 {
  padding-left:0px;
  padding-right:0;
}
.page_nieuws img {
  width:100%;
}
.share {
  width:280px;
}
.share a {
  width:120px;
  font-size:10px;
}

/* begin home */
.park {}
.stippen li {}
.stippen li .stip {
  width:16px;
  height:16px;
  -moz-border-radius:14px;
  -webkit-border-radius:14px;
  border-radius:14px;
  border:7px solid #e7e600;
  background-image:url(../../../images/stip.gif);
  background-repeat:no-repeat;
  background-position:center center;
}
.stippen li.bleep .stip {
  width:24px;
  height:24px;
  -moz-border-radius:22px;
  -webkit-border-radius:22px;
  border-radius:22px;
  margin-left:-4px;
  margin-top:-4px;
}
  
.stippen li div.ballon {
  top:-98px;
  left:-50px;
  width:150px;
  height:200px;
  overflow:hidden;
}
.stippen li.blauw div.ballon {
  background-image:url(../../../images/popup_blauw-mob.png);
}
.stippen li.geel1 div.ballon {
  background-image:url(../../../images/popup_geel1-mob.png);
  left:-50px;
}
.stippen li.geel2 div.ballon {
  background-image:url(../../../images/popup_geel2-mob.png);
  left:-60px;
}
#wrapper .stippen li div.ballon span {
  width:140px;
  padding:12px 10px 0 13px;
  font-size:15px;
  line-height:20px;
}
#wrapper .stippen li.popup1 div.ballon span {
  padding-top:22px;
  line-height:22px;
}

#wrapper .stippen li.popup1 { margin:164px 0 0 -140px; z-index:120000; } /* Gezonder leven */
#wrapper .stippen li.popup2 { margin:-37px 0 0 90px; } /* Twaalf jaar getrouwd */
#wrapper .stippen li.popup3 { margin:43px 0 0 -80px; z-index:110000; } /* Moe en hoofdpijn */
#wrapper .stippen li.popup4 { margin:-110px 0 0 -290px; } /* Nieuwe liefde */
#wrapper .stippen li.popup5 { margin:100px 0 0 190px; } /* Mijn vrouw heeft kanker */
/* end home */

.page_home #footer {
  display:block;
}
#footer .kolom {
  padding:20px 0 0;
  width:100%;
}
#footer .kolom1 {
  text-align:center;
  line-height:20px;
}
#footer .kolom2 {
  display:none;
}
#footer ul.contact {
  position:absolute;
  top:65px;
  left:0;
  height:46px;
  width:100%;
  padding:0;
  background:#67686a;
}
#footer ul.contact li {
  float:left;
  width:33%;
  text-align:center;
}
#wrapper #footer ul.contact li a {
  width:100%;
  margin-top:5px;
  font-size:16px!important;
  height:36px;
  border-right:1px solid #fff;
}
#wrapper #footer ul.contact li.adres a {
  border:0;
}
#footer ul.contact li a u.desktop {
  display:none;
}
#footer ul.contact li a u.mobile {
  display:block;
}
#footer ul.contact li a em {
  position:relative;
  top:5px;
  width:26px;
  margin-left:3px;
  height:26px;
  display:inline-block;
  background:url(../../../images/mobile.png) no-repeat;
}
#footer ul.contact li.bel em { background-position:0 0; }
#footer ul.contact li.mail em { background-position:-25px 0; }
#footer ul.contact li.adres em { background-position:-58px 0; }

#colofon,
#footer #colofon * {
  font-size:12px;
}

    }
