/* Globale layout */

a {
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
  -webkit-transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

:focus {
  outline: none;
}

body {
  margin:0;
  padding:0;
  width:100%;
  -webkit-text-size-adjust: none;
}
#wrapper {
  position:relative;
  width:100%;
  padding-top:87px;
  min-width:320px;
}
#header {
  border-bottom:2px solid red;
  background:#fff;
  height:85px;
  width:100%;
  position:absolute;
  top:0px;
  left:0px;
}
#header .contact {
  text-decoration:none;
  font-style:normal;
  font-size:16px;
  float:right;
  margin:29px 23px 0 0;
}
#zoeken {
  float:right;
  margin:27px 23px 0 0;
  width:281px;
  height:31px;
  border:1px solid rgb(173,175,175);
  overflow:hidden;
  background:url(../../../images/zoek.gif) right top no-repeat;
}
#zoeken input[type=text] {
  width:235px;
  font-family: 'Droid Serif', serif;
  font-style:italic;
  font-size:15px;
  color:rgb(173,175,175);
  transition: color 0.3s;
  -webkit-transition: color 0.3s;
  margin:7px 0 0 10px;
  border:0;
  float:left;
  padding:0;
}
#zoeken input[type=text]:focus {
  color:#666;
}
#zoeken input[type=submit] {
  cursor:pointer;
  width:32px;
  height:32px;
  float:right;
  border:0;
  padding:0;
  margin:0;
  background:#fff;
}

@media screen and (max-width:800px) {
#zoeken {
  width:151px;
}
#zoeken input[type=text] {
  width:105px;
}
}

#header_image {}
#header_image img {
  width:100%;
  display:block;
  height: auto;
}

#stuurdoor {
  position:absolute;
  width:100%;
  max-width:900px;
  left:50%;
  margin:50px 0 0 -460px;
}
@media screen and (max-width:900px) {
#stuurdoor {
  left:auto;
  right:25px;
}
}

#stuurdoor a { 
  position:absolute;
  right:0;
}
#stuurdoor a, #stuurdoor a strong { 
  display:block;
  width:116px;
  height:116px;
  background:url(../../../images/stuurdoor.gif) 0 -116px;
  /*
  transition: background-position 0.3s;
  -webkit-transition: background-position 0.3s;
  */
}
/*.no_touch #stuurdoor a:hover { background-position:0 -116px; }*/
#stuurdoor a span { display:none; }

#stuurdoor a strong {
  background-position: 0 0;
}


#menu {
  position:absolute;
  top:87px;
  left:0px;
  background:#fff url(../../../images/menu.gif) no-repeat center 8px;
  z-index:120;
  min-width:100px;
  height:1px;

  padding-top:49px;
  width:auto;
  /*height:auto;*/
}
#menu ul {
  display:none;
}
/* Alternatief voor javascript
#menu:hover ul {
  display:block;
}
*/
#menu ul, #menu ul li {
  list-style: none;
  padding:0;
  margin:0;
}
#menu ul li {
  display:block;
  padding:0 15px;
  background:#fff;
  border-top:1px solid #fff;
}
#menu ul li a {
  display:block;
  font-size:17px;
  height:48px;
  line-height:49px;
  border-top:1px solid blue;
  padding:0 15px;
  text-align:center;
  text-decoration:none;
}
#menu, #menu li {
  -moz-box-shadow:    2px 2px 2px 0px #222;
  -webkit-box-shadow: 2px 2px 2px 0px #222;
  box-shadow:         2px 2px 2px 0px #222;
}

.hr {
  background:url(../../../images/stippellijn.gif) repeat-x center center;
  height:13px;
}
.containter {}
.containter .wrapper {
  margin:auto;
  /*width:866px;*/
  /*padding:33px 0 33px 134px;
  padding:33px 290px 33px 134px;*/
  max-width:576px;
  padding:33px 152px 33px 150px;
}


iframe.map {
  width:580px;
  height:330px;
  margin:33px 0;
  border:1px solid #333;
}

@media screen and (max-width:800px) {
.containter .wrapper {
  padding-left:30px;
  max-width:696px;
}
.page_referenties .wrapper {
  max-width:400px;
}
.page_referenties .wrapper h1 {
  padding-left:60px;
  padding-right:0;
}
 iframe.map {
  width:100%;
  margin:0 -1px;
}
}
.odd {
  background:#e0ddd2;
}

.containter .quote {
  font-style:italic;
}
.containter .quote.Groot {
  font-size:28px;
  line-height:36px;
}
.containter .quote.Klein {
  font-size:20px;
  line-height:32px;
}
.containter .credit {
  margin:33px 0 0;
  font-size:20px;
}
.containter .logos {
  margin:0 0 30px 0;
  /*width:640px;*/
}
.containter .logos img {
  margin:30px 30px 0 0;
}
.containter img.klein {
  display:block;
  margin:9px 0;
}
.containter img.groot {
  display:block;
  width:100%;
  margin:33px 0;
}
.containter iframe.ytplayer {
  width:580px;
  height:330px;
  margin:33px 0;
}
.containter a.leesmeer {
  text-decoration:none;
}
.containter div.leesmeer {
  display:none;
}
.containter div.referenties ,
.containter div.naarboven {
  position:relative;
  max-width:728px;
}
.containter div.naarboven {
  margin-top:60px;
}
.containter .referenties a ,
.containter .naarboven a {
  position:absolute;
  z-index:600;
  right:0px;
  display:block;
  /*
  transition: background-position 0.3s;
  -webkit-transition: background-position 0.3s;
  */
}
.containter .referenties a strong,
.containter .naarboven a strong {
  display:block;
}
.containter .referenties a, .containter .referenties a strong {
  background:url(../../../images/referenties.gif) 0 -115px no-repeat;
  width:115px;
  height:115px;
}
.containter .referenties a {
  bottom:-90px;
}
.containter .naarboven a, .containter .naarboven a strong {
  width:70px;
  height:68px;
  bottom:0px;
}
.containter .referenties a strong {
  background-position:0 0;
}
.no_touch .containter .naarboven a:hover, .no_touch .containter .naarboven a.active {
  background:url(../../../images/naarboven.png) 0 -69px no-repeat;
}
.containter .naarboven a strong {
  background:url(../../../images/naarboven.png) 0 0 no-repeat;
  background-position:0 0;
}
.containter .referenties a span ,
.containter .naarboven a span {
  display:none;
}

a.button {
  display:block;
  width:106px;
  height:44px;
  font-family:arial,helvetica,sans-serif;
  font-weight:bold;
  color:#fff;
  text-decoration:none;
  line-height:22px;
  text-align:center;
  padding:6px 10px;
  border-radius:5px;
}
a.button.Direct {
  padding-top:17px;
  height:33px;
}
.no_touch a.button:hover {
  background-color:#76787a;
}

.page_paginas li {
  margin-bottom:0;
}

/* Nieuws */
#nieuws .wrapper {
  padding-bottom:10px;
}
#nieuws .datum {
  display:block;
  margin-bottom:20px;
}
/*#nieuws img {
  display:block;
  margin:30px 0;
  width:100%;
  max-width:580px;
}*/

#nieuws .image {
  position:relative;
  clear:both;
}
#nieuws .image iframe {
  margin:30px 0;
  width:580px;
  height:435px;
}
#nieuws .image img {
  display:block;
  margin:30px 0;
  max-width:580px;
  width:100%;
  height: auto;
}
#nieuws .image .playbutton {
  display:none;
}
#nieuws .image.youtube {
  cursor:pointer;
}
#nieuws .image.youtube .playbutton {
  display:block;
  position:absolute;
  width:100%;
  top:50%;
  margin-top:-60px;
  height:120px;
  background:url(btn_play.png) center center no-repeat;
}

.share {
  margin:30px 0 0px;
  width:340px;
}
.share a {
  float:left;
  width:150px;
  color:#616365;
  text-decoration:none;
  margin:0 15px 8px 0;
  font-size:14px;
  line-height:22px;
  
}
.share a span {
  float:left;
  background:url(../../../images/share.gif) no-repeat;
  width:22px;
  height:22px;
  margin-right:8px;
}
.share .tweet    span { background-position:0 0; }
.share .linkedin span { background-position:-22px 0; }
.share .facebook span { background-position:-44px 0; }
.share .send     span { background-position:-66px 0; }
#nieuws .containter .referenties a {
  bottom:-67px;
}


/* Contact */

.containter ul.contact, .containter ul.contact li {
  list-style:none;
  margin:0;
  padding:0;
  font-family:arial,helvetica,sans-serif;
  font-weight:bold;
}
.containter ul.contact {
  margin-top:20px;
}
ul.contact li a {
  display:block;
  height:100px;
  margin-top:10px;
  padding:0 0 0 0;
  font-size:19px!important;
  text-decoration:none;
}

ul.contact li a u {
  text-decoration:none;
}
ul.contact li a span, ul.contact li a span strong {
  display:block;
  width:83px;
  height:82px;
}
ul.contact li a span {
  float:left;
  margin:0 24px 0 0;
  /*
  transition: background-position 0.3s;
  -webkit-transition: background-position 0.3s;
  */
}
ul.contact li a span strong {
  background:url(../../../images/contact.png) no-repeat 0 0;
}
/*
ul.contact li.mail a span { background-position:0 -166px; }
ul.contact li.adres a span { background-position:0 -332px; }
.no_touch #footer ul.contact li.bel a:hover span { background-position:0 -83px; }
.no_touch #footer ul.contact li.mail a:hover span { background-position:0 -249px; }
.no_touch #footer ul.contact li.adres a:hover span { background-position:0 -415px; }
*/
ul.contact li.bel a span strong { background-position:0 -1px; }
ul.contact li.mail a span strong { background-position:0 -167px; }
ul.contact li.adres a span strong { background-position:0 -333px; }
.no_touch #footer ul.contact li a.active span { background:url(../../../images/contact.png) no-repeat; }
.no_touch #footer ul.contact li.bel a.active span { background-position:0 -83px; }
.no_touch #footer ul.contact li.mail a.active span { background-position:0 -249px; }
.no_touch #footer ul.contact li.adres a.active span { background-position:0 -415px; }

@media screen and (max-width: 600px) {

#nieuws .image iframe {
  width:300px;
  height:225px;
}

ul.contact li a {
  font-size:15px!important;
}
ul.contact li a span {
  margin-right:10px;
}
}

#footer {
  font-family:arial,helvetica,sans-serif;
  font-weight:bold;
}
#footer ul, #footer li {
  list-style:none;
  margin:0;
  padding:0;
}

#footer .wrapper {
  margin:auto;
  max-width:980px;
  color:#fff;
  line-height:28px;
}
#footer .AsInPage {
  margin:10px 0 -30px;
  position:relative;
  z-index:100;
}
#footer .kolom {
  float:left;
  padding-top:40px;
}
#footer .kolom1 {
  width:320px;
}
#footer .kolom2 {
  width:280px;
}
#footer .kolom3 {
  width:370px;
}
@media screen and (max-width:1000px) {
#footer .kolom {
  padding-left:20px;
}
}
#footer ul.contact li a {
  width:370px;
  height:115px;
}
#footer ul.contact li a u.mobile {
  display:none;
}
#footer ul.contact li a u.desktop span {
  margin-top:-10px;
}
#footer ul.contact li.adres a {
  margin-top:-1px;
}
#footer ul.contact li.adres a u.desktop span {
  margin-top:0px;
}
#footer strong {
  font-size:19px;
  font-weight:bold;
}
#footer .kolom ul {
  margin-top:20px;
}
#footer .sm {
  clear:both;
  text-align:center;
  margin:0px 0 15px;
}
#footer .sm li {
  display:inline-block;
  margin-top:30px;
}
#footer .sm a, #footer .sm a strong {
  display:block;
  width:46px;
  height:44px;
  background:url(../../../images/footer_sm.gif);
}
#footer .sm a {
  margin:0 12px;
}
#footer .sm a span { display:none; }
#footer .sm .Twitter a strong {  background-position: -1px -1px; }
#footer .sm .Facebook a strong { background-position: -78px -1px; }
#footer .sm .LinkedIn a strong { background-position: -154px -1px; }
/*
.no_touch #footer .sm .Twitter a:hover {  background-position: -1px -45px; }
.no_touch #footer .sm .Facebook a:hover { background-position: -78px -45px; }
.no_touch #footer .sm .LinkedIn a:hover { background-position: -154px -45px; }
*/
.no_touch #footer .sm .Twitter a {  background-position: -1px -45px; }
.no_touch #footer .sm .Facebook a { background-position: -78px -45px; }
.no_touch #footer .sm .LinkedIn a { background-position: -154px -45px; }

#footer a {
  font-size:15px;
  color:#fff;
  text-decoration:none;
}

#footer ul#colofon {}
#footer ul#colofon li {
  display:none;
}
#footer ul#colofon li.first {
  display:block;
  text-align:center;
  font-weight:normal;
  padding:4px 0 12px;
}
#footer ul#colofon li.first a {
  //display:;
  text-decoration:none;
  text-transform:uppercase;
}

/* Homepage */
.page_home h1 {
  display:none;
}
.page_home #footer {
  display:none;
}

.page_home ul.buttons, .page_home ul.buttons li {
  list-style: none;
  padding:0;
  margin:0;
}
.page_home ul.buttons {
  position:absolute;
  top:50%;
  right:22%;
  margin-right:-110px;
  margin-top:-116px;
  /*
  left:50%;
  margin-left:-380px;
  */
  z-index:120;
  font-family:arial,helvetica,sans-serif;
  font-weight:bold;
}
.page_home ul.buttons li {
  margin-bottom:18px;
  font-size:37px;
  float:left;
  clear:left;
}
.page_home ul.buttons li a {
  height:69px;
  background-color:#fff;
  position:relative;
  text-decoration:none;
  display:block;
  line-height:69px;
  padding:0 118px 0 30px;
  transition: margin 0.3s;
  -webkit-transition: margin 0.3s;
}
.page_home ul.buttons li a span {
  position:absolute;
  right:13px;
  top:12px;
  width:45px;
  height:45px;
  background:url(../../../images/pijl-r.gif) no-repeat 0 0;
  
  transition: background-position 0.3s;
  -webkit-transition: background-position 0.3s;
}
.no_touch.page_home ul.buttons li:hover a span {
  background-position:-40px 0;
}
.page_home ul.buttons li.button1 a { margin-left:36px;  margin-right:5px; }
.page_home ul.buttons li.button2 a { margin-left:156px; margin-right:5px; }
.page_home ul.buttons li.button3 a { margin-left:0px;   margin-right:5px; }
.no_touch.page_home ul.buttons li.button1 a:hover { margin-left:41px;  margin-right:0px; }
.no_touch.page_home ul.buttons li.button2 a:hover { margin-left:161px; margin-right:0px; }
.no_touch.page_home ul.buttons li.button3 a:hover { margin-left:5px;   margin-right:0px; }


/* Homepage slider */

.slider_controllers {
  position:absolute;
  right:0px;
  top:50%;
  margin-top:-10px;
  z-index:120;
  text-transform:uppercase;
  font-family:arial,helvetica,sans-serif;
  font-size:15px;
  line-height:13px;
  text-align:center;
}
.slider_controllers div {
  color:#fff;
  margin:3px 0;
  padding:16px 9px 14px;
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
}
			
.slider_container {
  width:100%;
  height:300px;
}
.slider_container .iosSlider {
	position: relative;
	top: 0;
	left: 0;
	overflow: hidden;
	width: 100%;
	height: 100%;
}
.slider_container .iosSlider .slider {
	width: 100%;
	height: 100%;
}
.slider_container .iosSlider .slider .item {
	float: left;
	width: 100%;
	height: 100%;
}

/*
.responsive_height {
  z-index:1;
  width: 100%;
  height: 0;
  padding: 0 0 48% 0; 
  position: relative;
  overflow: visible;
}
.responsive_height .slider_container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.iosSlider {
  width: 100%;
  height: 1500px;
}			
.iosSlider .slider {
  width: 100%;
  height: 100%;
}
.iosSlider .slider .item {
  position: relative;
  top: 0;
  left: 0;
  

  width: 100%;
  height: 100%;
  margin: 0 0 0 0;
}
.iosSlider .slider .item img {
  width: 100%;
}
*/


/* 28 mei 2015 aangepast blog */

.aanmeld_button {
  display:block;
  position:relative;
  width:116px;
  height:116px;
  font-size:18px;
  line-height:22px;
  color:#fff;
  text-align:center;
  font-family:arial,helvetica,sans-serif;
  background:url(../../../images/abonneer.gif);
  margin-bottom:30px;
}
.aanmeld_button strong {
  padding-top:12px;
  width:116px;
  height:104px;
  position:absolute;
  top:0;
  left:0;
}

@media (min-width:600px) {
.aanmeld_button {
  position:absolute;
  right:24px;
  margin:0;
}
.parent_nieuws #stuurdoor {
  margin-top:200px;
}
}

@media (min-width:920px) {
.aanmeld_button {
  position:absolute;
  margin:4px 0 0 613px;
  right:auto;
}
}

.nieuws_overzicht {}
.nieuws_overzicht .kop.h3 {}
.nieuws_overzicht .hr,
#nieuws .reactie .hr {
  width:214px;
  clear:both;
  margin:8px 0;
}
.nieuws_overzicht a.list_item {
  display:block;
  padding-left:170px;
  text-decoration:none;
}
.nieuws_overzicht a.list_item * {
  transition:color 0.2s;
}
.nieuws_overzicht .list_item img {
  float:left;
  width:150px;
  margin:0 0 12px -170px;
}
#nieuws .nieuws_overzicht .list_item .datum {
  margin:0;
}
.nieuws_overzicht .list_item h3 {}

.pageselect {
  font-family:arial,helvetica,sans-serif;
  font-weight:bold;
  font-size:110%;
}
.pageselect span.previous,
.pageselect span.next {
  display:none;
}

.pageselect .wp-pagenavi span,
.pageselect .wp-pagenavi a,
.pageselect .wp-pagenavi strong {
  display:inline-block;
  padding:0 5px;
}
.pageselect a {
  text-decoration:none;
}
.pageselect .wp-pagenavi strong {
  text-decoration:underline;
}

form.form {
  /*margin:0 39px 60px 0;
  width:100%;*/
  margin:30px 0 90px 0;
}
.mailpoet_form,
form.form div.form {
  background:#efeee8;
  padding:30px 20px 20px;
  box-sizing: border-box;
}
.mailpoet_form .mailpoet_paragraph,
.form h3, .form .row {
  border-bottom:1px solid #626466;
  padding-bottom:8px;
}
.form h3 {}
.mailpoet_form .mailpoet_paragraph,
.form .row {
  clear:both;
  width:100%;
  padding-top:8px;
}
.mailpoet_form .mailpoet_paragraph.last{
  border:none!important;
  padding:0!important;
}
.mailpoet_form label,
.form label {
  float:left;
  width:25%;
}

.form .row.textarea {
  padding-bottom:0px;
}
.form .row.textarea label {
  float:none;
}
.mailpoet_form input[type=email],
.mailpoet_form input[type=text], .mailpoet_form textarea,
.form input[type=text], .form textarea {
  font-family:arial,helvetica,sans-serif;
  font-size:15px;
  box-sizing:border-box;
  padding:0px;
  border:0;
  background:#efeee8;
}
.mailpoet_form input[type=email],
.mailpoet_form input[type=text],
.form input[type=text] {
  width:75%;
}
.mailpoet_form textarea,
.form textarea {
  width:100%;
  min-width:100%;
  max-width:100%;
  height:70px;
}
.mailpoet_form input[type='submit'],
.form input[type=submit] {
  font-family:arial,helvetica,sans-serif;
  padding:15px 30px!important;
  border-radius:5px!important;
  border:0!important;
  font-weight:bold;
  color:#fff!important;
  cursor:pointer;
  font-size:115%;
  transition:background-color 0.2s;
  margin:15px 0 0!important;
  float:right!important;
  -webkit-appearance: none;
  -webkit-border-radius: 5px!important;
  border-radius: 5px!important;
  background-color: #8dc641!important;
}
.mailpoet_form input[type=submit]:hover,
.form input[type=submit]:hover {
  background-color:#626466!important;
}
.parsley-errors-list  li{
  margin: 0!important;
}
#nieuws .referenties {
  left:152px;
}

@media (max-width:630px) {
  .form {
    font-size:80%;
  }
  .form label {
    width:35%;
  }
  .form input[type=text] {
    width:65%;
  }
}

#nieuws .error { color: #cc6666 }
#nieuws .error li { margin:0 }
#nieuws .reacties {
  margin-top:-70px;
}
