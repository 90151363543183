.AShareOverlay {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  background:#000; /* url(http://www.accessite.nl/accessite/images/loading.gif) no-repeat center 150px; */
  z-index:2000;
}
.AShareWrapper {
  position:fixed;
  top:100px;
  left:50%;
  width:600px;
  margin-left:-300px;
  background-color:#fff;
  border-radius:12px;
  min-height:100px;
  box-shadow:3px 3px 3px 0 #666;
  font-size:15px;
  font-family:arial,helvetica,sans-serif;
  line-height:20px;
  color:#666;
  font-weight:normal;
  font-style:normal;
  z-index:2001;
}
.AShareFrame {
  width:1px;
  height:1px;
  visibility:hidden;
}
.AShareForm, .AShareSuccess {
  margin:0px 30px 20px;
}
.AShareWrapper strong {
  font-size:30px;
  line-height:50px;
  font-weight:normal;
  display:block;
  margin:0 0 10px 30px;
}
.AShareInput, .AShareTextarea {
  border:1px solid #ccc;
  border-radius:3px;
  padding:6px;
  clear:both;
  margin-bottom:8px;
}
.AShareInput {
  transition: border-color 0.3s, color 0.3s;
  -webkit-transition: border-color 0.3s, color 0.3s;
}
.AShareInput label, .AShareTextarea label {
  display:block;
  width:170px;
}
.AShareInput label {
  float:left;
}
.AShareInput input, .AShareTextarea textarea {
  padding:0;
  margin:0;
  border:0;
  width:340px;
  font-size:15px;
  font-family:arial,helvetica,sans-serif;
  color:#666;
  transition: color 0.3s;
  -webkit-transition: color 0.3s;
}
.AShareInput input:focus, .AShareTextarea textarea:focus {
  outline:none;
  color:#333;

}
.AShareInput input {}
.AShareTextarea {}
.AShareTextarea label {}
.AShareTextarea textarea {
  width:100%;
  min-width:100%;
  max-width:100%;
  height:80px;
  min-height:20px;
  max-height:250px;
  margin-top:8px;
}

.AShareSubmit input {
  float:right;
  background-color:#42a04b;
  color:#fff;
  font-size:20px;
  border-radius:3px;
  border:1px outset #287e30;
  margin:12px 0;
  padding:6px 15px;
  cursor:pointer;
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
}
.AShareSubmit input:hover {
  background-color:#337939;
}
.AShareError {
  display:none;
  padding-bottom:10px;
}
.AShareError, .AShareInput.error, .AShareInput.error input {
  color:#ea7222;
}
.AShareInput.error, .AShareInput.error input {
  background:#fbe7c3;
  border-color:#ea7222;
}
.AShareTip {
  float:left;
  margin-top:12px;
  width:340px;
}

.AShareClose {
  position:absolute;
  top:25px;
  right:22px;
  width:20px;
  height:20px;
  border-radius:10px;
  background:#666;
  color:#fff;
  text-align:center;
  font-weight:normal;
  line-height:15px;
  font-size:18px;
  font-family:verdana,arial,helvetica,sans-serif;
  cursor:pointer;
  transition: background-color 0.3s;
  -webkit-transition: background-color 0.3s;
}
.AShareClose:hover {
  background:#999;
}

