/* Kleuren en project eigen instellingen */

.nieuws-txt-img {
  width:80%;
  margin:0.5em 1em 1em 0;
}
.nieuws-txt-img.staand {
  width:auto;
  max-height:20em;
}
@media (max-width:600px) {
  .nieuws-txt-img.liggend {
    width:100%;
  }
}


/* Kleur 1 van lijnen */
body #header {
  border-color:#17a8de;
}
/* Kleur 2 van lijnen */
body #menu ul li a {
  border-color:#17a8de;
}

/* Kleur 1 van vlakken */
body.no_touch .slider_controllers div:hover ,
a.button,
.aanmeld_button strong,
.form input[type=submit] {
  background-color:#8dc641;
}

/* Kleur 2 van vlakken */
body .slider_controllers div ,
#footer .background
{
  background-color:#616365;
}

/* Kleur 1 */
body.page_home ul.buttons li a ,
.no_touch #menu ul li a:hover ,
.no_touch #header .contact:hover ,
.containter .quote ,
.containter .credit ,
a.leesmeer ,
.no_touch .share a:hover ,
.no_touch ul.contact li a:hover ,
.no_touch #footer a:hover,
#nieuws .kop.h3,
.nieuws_overzicht a:hover *,
.pageselect *
 {
  color:#8dc641;
}

/* Kleur 2 */
body.no_touch.page_home ul.buttons li a:hover ,
#menu ul li a ,
#header .contact 
{
  color:#17a8de;
}

body #logo img {
  /* Beschikbare hoogte: 85px */
  margin:10px 0 0 84px;
}

#menu ul li a {
  color:#333;
}
.no_touch #menu ul li a:hover {
  color:#17a8de;
}


.park {
  width:100%;
  height:300px;
	overflow: hidden;
	position: relative;
}
.stippen {
  list-style:none;
  display:block;
  margin:0;
  padding:0;
	position: absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
}
.stippen li {
  list-style:none;
  display:block;
  margin:0;
  padding:0;
	position: absolute;
  z-index:10000;
  cursor:pointer;
}
.stippen li .stip {
  width:24px;
  height:24px;
  -moz-border-radius:22px;
  -webkit-border-radius:22px;
  border-radius:22px;
  color:#00a9e0;
  background:#00a9e0;
  border:10px solid #e7e600;
  
  transition: width 0.2s, height 0.2s, margin 0.2s, border-radius 0.2s;
  -webkit-transition: width 0.2s, height 0.2s, margin 0.2s, -webkit-border-radius 0.2s;
  
  behavior: url(../inc/pie/PIE.html);  
}
.stippen li.bleep .stip
/*,.no_touch .stippen li:hover */
{
  width:34px;
  height:34px;
  -moz-border-radius:32px;
  -webkit-border-radius:32px;
  border-radius:32px;
  margin-left:-5px;
  margin-top:-5px;
  
  behavior: url(../inc/pie/PIE.html);  
}
.stippen li div.ballon {
	position: absolute;
  top:-227px;
  left:-80px;
  display:none;
  background-position:left bottom;
  background-repeat:no-repeat;
  width:270px;
  height:232px;
  overflow:hidden;
}
.stippen li.blauw div.ballon {
  background-image:url(../../../images/popup_blauw.png);
}
.stippen li.geel1 div.ballon {
  background-image:url(../../../images/popup_geel1.png);
  left:-93px;
}
.stippen li.geel2 div.ballon {
  background-image:url(../../../images/popup_geel2.png);
  left:-125px;
}
.stippen li div.ballon span {
  display:block;
  width:240px;
  padding:45px 10px 0 24px;
  color:#fff;
  font-size:27px;
  line-height:34px;
}
.stippen li.popup1 div.ballon span {
  padding-top:59px;
}
.stippen li.popup3 div.ballon span {
  line-height:32px;
}
.stippen li.popup4 div.ballon span {
  padding-top:18px;
}
.stippen li.popup5 div.ballon span {
  padding-top:26px;
}


body {
  font-family: 'Droid Serif', serif;
  font-style:normal;
  font-size:15px;
  line-height:27px;
  padding:20px;
  margin:0;
  background-color:#ffffff;
  color:#616365;
}

img {
  border:0;
}

a {
  text-decoration:underline;
  color:#616365;
}

/* Reset */
ul,ol,li,p,h1,h2,h3,h4,h5,h6,form,pre {
  margin:0;
}

li {
  margin:0 0 28px -24px;
}

h1, .h1, h2, .h2, .page_contact h3 {
  font-family:arial,helvetica,sans-serif;
  font-weight:bold;
  font-size:26px;
  line-height:31px;
  margin-bottom:10px;
}
h3, .h3 {
  font-family:arial,helvetica,sans-serif;
  font-weight:bold;
  font-size:18px;
  line-height:29px;
}

.clear {
  clear:both;
}
